<template>
  <div class="content-wrapper">
    <div class="orders main-content">

      <div class="page-header">
        <h1>Normal Trades</h1>
      </div>

      <div class="view-toolbars">
        <ItemFilter class="fitler" placeholder="Enter Trade Name" @search="searchTrade" @reset="resetFilter">
          <el-form class="search-form" :model="searchForm">
            <div class="row">
              <el-form-item label="Reference ID">
                <el-input v-model="searchForm.reference_id"></el-input>
              </el-form-item>
              <el-form-item label="Principal Employee Name">
                <el-input v-model="searchForm.employee_name"></el-input>
              </el-form-item>
              <el-form-item label="Action Type" style="max-width: 550px;">
                <el-select v-model="searchForm.action_type" placeholder="Select the action type">
                  <el-option label="Income" value="Income"></el-option>
                  <el-option label="Outcome" value="Outcome"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Status" style="max-width: 550px;">
                <el-select v-model="searchForm.status" placeholder="Select the status">
                  <el-option label="Pending" value="Pending"></el-option>
                  <el-option label="Paired" value="Paired"></el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-form>
        </ItemFilter>
        <div class="tool">
          <router-link :to="{name:'CreateTrade'}">
            <button class="btn btn-primary">
              <i class="el-icon-plus"></i>
              New
            </button>
          </router-link>
        </div>
      </div>

      <div class="trade-list">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Reference ID</th>
              <th scope="col">Principal Employee Name</th>
              <th scope="col">Trade Name</th>
              <th scope="col">Action Type</th>
              <th scope="col">Status</th>
              <th scope="col">Amount</th>
              <th scope="col">Create Date</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="trade in trades">
              <td>{{trade.reference_id}}</td>
              <td>{{trade.employee_name}}</td>
              <td>{{trade.trade_name}}</td>
              <td>{{trade.action_type}}</td>
              <td>{{trade.status}}</td>
              <td>${{trade.amount}}</td>
              <td>{{ formatDateTime(trade.create_date, 'YYYY-MM-DD HH:mm:ss') }}</td>
              <td class="action">
                <router-link :to="{name:'Trade', params: {id: trade.id}}">View</router-link>
                <a href="#" class="delete-link" @click="deleteTrade(trade.id)">Delete</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
// @ is an alias to /src
import ItemFilter from '@/components/ItemFilter.vue';
import Common from '@/lib/common';
import Financial from '@/lib/financial';

export default {
  name: 'Trades',
  components: {
    ItemFilter,
  },
  data(){
    return {
      searchForm: {
        reference_id: '',
        employee_name: '',
        action_type: '',
        status: '',
      },
      originTrades: [],
      trades: [],
    }
  },
  mounted(){
    this.loadTrades();
  },
  methods:{
    async loadTrades(){
      const loginInfo = Common.getLoginInfo();
      try{
        const searchFields = {
          'type': 'Normal',
        };
        const trades = await Financial.searchTrade(this.apiUrl, searchFields, loginInfo);
        this.originTrades = trades;
        this.trades = trades;
      }catch(err){
        console.log(err);
      }
    },
    resetFilter(){
      //Clean Filter
      for(let i = 0; i < Object.keys(this.searchForm).length; i++){
        const searchKey = Object.keys(this.searchForm)[i];
        this.searchForm[searchKey] = '';
      }
      this.trades = this.originTrades;
    },
    searchTrade(textSearchValue){
      this.searchForm['trade_name'] = textSearchValue;
      const searchFilter = this.searchForm;
      const newTrades = Common.filterItems(searchFilter, this.originTrades);
      this.trades = newTrades;
    },
    formatDateTime(timestamp, format){
      const date = Common.formatDateTime(timestamp, format);
      return date;
    },
    async deleteTrade(tradeId){
      const loginInfo = Common.getLoginInfo();
      try{
        await Financial.deletePayment(this.apiUrl, tradeId, loginInfo);
        const newPayments = this.trades.filter(function(item) {
          return item.id !== tradeId;
        });
        this.trades = newPayments;
      }catch(err){
        console.log(err);
      }
    },
  },
  computed: mapState({
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style lang="scss">
.view-toolbars{
  display: flex;
  .fitler{
    flex: 1;
  }
  .el-icon-plus{
    font-size: 1.2em;
  }
}
.action{
  a{
    display: inline-block;
    margin-right: 10px;
  }
  .delete-link{
    color: red;
  }
}
</style>
